<template>
    <item-list
        ref="list"
        :endpoint="endpoint"
        :itemComponent="PostListSelectorItem"
        empty-text="No results for this search"
        @select-item="selectItem"
    ></item-list>
</template>

<script>
    import { shallowRef } from 'vue';
    import PostListSelectorItem from './PostListSelectorItem.vue';
    import ItemList from '@/components/common/ItemList.vue';

    export default {
        name: 'PostListSelector',
        emits: ['update:value'],
        props: {
            value: {
                type: Object,
                required: false
            },
        },
        components: {
            ItemList,
        },
        data() {
            return {
                PostListSelectorItem: shallowRef(PostListSelectorItem),
                selectedPost: this.$props.value,
            };
        },
        methods: {
            selectItem(item){
                if(this.selectedPost){
                    this.selectedPost.selected = false;
                }
                this.$emit('update:value', item);
                this.selectedPost = item;
            }
        },
    }
</script>

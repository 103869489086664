<template>
    <div class="mb-4">
        <article class="card card--shadow-border mb-4">
            <section class="card-body">
                <section class="d-flex justify-content-between mb-2">
                    <div>
                        <b-button variant="outline-dark" v-b-modal="scheduleModalId">
                            Schedule for
                            <i class="fa fa-facebook-square icon mr-1" v-if="profile.applications.facebook.is_enabled"></i>
                            <i class="fa fa-instagram mr-1" v-if="profile.applications.instagram.is_enabled"></i>
                            <i class="fa fa-linkedin mr-1" v-if="profile.applications.linkedin.is_enabled"></i>
                        </b-button>
                    </div>
                    <b-dropdown class="py-2" size="sm" variant="default" no-caret>
                        <template #button-content>
                            <i class="fa fa-lg fa-ellipsis-h" aria-hidden="true"></i>
                        </template>
                        <b-dropdown-item v-b-modal="scheduleModalId">{{ $t('common.schedule') }}</b-dropdown-item>
                        <b-dropdown-item v-b-modal="editModalId">{{ $t('common.edit') }}</b-dropdown-item>
                        <b-dropdown-item v-b-modal="deleteModalId">{{ $t('common.delete') }}</b-dropdown-item>
                    </b-dropdown>
                </section>
                <p class="text-breakspaces mb-0">{{ post.message }}</p>
            </section>
            <main class="card-footer card-footer--no-padding card-footer--no-link">
                <div class="overflow-hidden rounded-bottom">
                    <img
                        v-lazy="{ src: post.data.image.image_full, loading: post.data.image.image_thumb }"
                        alt=""
                        class="img-fluid w-100">
                </div>
                <p class="text-right small my-0" v-html="post.data.image.image_credit_html">
                </p>
            </main>
        </article>

        <generated-post-edit-modal
            :id="editModalId"
            :post="post"
            :endpoint="endpointGeneratedPostingDetail"
            :okCallback="refresh"
        ></generated-post-edit-modal>

        <generated-post-delete-modal
            :id="deleteModalId"
            :post="post"
            :endpoint="endpointGeneratedPostingDetail"
            :okCallback="remove"
        ></generated-post-delete-modal>

        <generated-post-schedule-modal
            :id="scheduleModalId"
            :post="post"
            :endpoint="endpointGeneratedPostingSchedule"
            :okCallback="remove"
        ></generated-post-schedule-modal>

    </div>
</template>

<script>
    import { endpoints } from '../api/EndpointsConfig.js';
    import GeneratedPostEditModal from './GeneratedPostEditModal.vue';
    import GeneratedPostDeleteModal from './GeneratedPostDeleteModal.vue';
    import GeneratedPostScheduleModal from './GeneratedPostScheduleModal.vue';

    export default {
        name: 'GeneratedPost',
        components: {
            GeneratedPostEditModal,
            GeneratedPostDeleteModal,
            GeneratedPostScheduleModal,
        },
        props: {
            item: {
                type: Object,
                required: true
            },
            refreshList: {
                type: Function,
                required: false
            }
        },
        emits: ['update-item', 'delete-item'],
        computed: {
            editModalId() {
                return `edit-modal${this.post.pk}`;
            },
            deleteModalId() {
                return `delete-modal${this.post.pk}`;
            },
            scheduleModalId() {
                return `schedule-modal${this.post.pk}`;
            }
        },
        data: function () {
            return {
                post: this.$props.item,
                endpointGeneratedPostingDetail: endpoints.generated_postings_detail(this.$props.item.pk),
                endpointGeneratedPostingSchedule: endpoints.generated_postings_schedule(this.$props.item.pk),
                profile: window.profileData,
            };
        },
        methods: {
            refresh(response){
                this.post = response.data;
                this.$emit('update-item', this.post);
            },
            remove(){
                this.$emit('delete-item', this.post.pk);
            },
        }
    }
</script>

<template>
    <confirm-and-wait-modal
        :okTitle="$t('common.save')"
        :title="$t('generator.profile.settingsTitle')"
        cancel-variant="light"
        :okCallback="saveCallback"
        :method="method"
        :endpoint="endpoint"
        :params="form">
        <div class="mb-3">
            <b-form>
                <b-form-group
                    :label="$t('generator.profile.voiceTone')"
                    label-for="voice_tone"
                    :description="$t('generator.profile.voiceToneDescription')"
                >
                    <b-form-select id="voice_tone" v-model="form.voice_tone" :options="voice_tone_options"></b-form-select>
                </b-form-group>
                <b-form-group
                    :label="$t('generator.profile.writtingStyle')"
                    label-for="writing_style"
                    :description="$t('generator.profile.writtingStyleDescription')"
                >
                    <b-form-select id="writing_style" v-model="form.writing_style" :options="writing_style_options"></b-form-select>
                </b-form-group>
                <b-form-checkbox
                    id="use-agency-name"
                    v-model="form.use_agency_name"
                    name="use-agency-name"
                >
                    {{ $t('generator.profile.useAgencyName') }}
                </b-form-checkbox>

                <b-form-group
                    class="mt-3"
                    label="Destinations"
                    label-for="destinations"
                    description="This list of destinations is defined in your profile settings. It will be used to select the images generated by the AI."
                >
                    <div id="destinations">
                        <span v-if="this.profile.destination1" class="badge badge-light badge-pill">{{ this.profile.destination1 }}</span>
                        <span v-if="this.profile.destination2" class="badge badge-light badge-pill">{{ this.profile.destination2 }}</span>
                        <span v-if="this.profile.destination3" class="badge badge-light badge-pill">{{ this.profile.destination3 }}</span>
                        <a :href="editDestinationsUrl" class="text-muted">
                            <i class="fa fa-pencil small ml-1" aria-hidden="true"></i>
                        </a>
                    </div>
                </b-form-group>
            </b-form>

            <p v-if="signedup" class="my-3 text-center">
                <button class="text-danger btn btn-default" @click="cancel_subscription()" :disabled="saving">
                    {{ $t('generator.profile.unsubscribe') }}
                </button>
            </p>
        </div>
    </confirm-and-wait-modal>
</template>

<script>
    import axios from '@/setup/axios-setup';
    import ConfirmAndWaitModal from '@/components/common/ConfirmAndWaitModal.vue';
    import { endpoints } from '../api/EndpointsConfig';
    import { endpoints as profileEndpoints } from '@/components/profile/api/EndpointsConfig';
    import { routes, voiceToneOptions, writingStyleOptions } from '../GeneratorConfig';
    import { views } from '@/components/common/views-config.js';
    import Toast from '@/toast.js';
    const toast = new Toast();

    export default {
        name: 'GeneratorProfileSettings',
        components: {
            ConfirmAndWaitModal,
        },
        emits: ['update'],
        data: function () {
            return {
                voice_tone_options: voiceToneOptions,
                writing_style_options: writingStyleOptions,
                method: 'PUT',
                endpoint: '',
                form: {
                    profile: null,
                    voice_tone: null,
                    writing_style: null,
                    use_agency_name: false,
                },
                profile: null,
                signedup: false,
            };
        },
        methods: {
            loadData() {
                this.profile = window.profileData;
                this.form.profile = this.profile.pk;
                this.signedup = this.profile.applications.generator.active;
                // get endpoint to use
                this.method = 'PUT';
                this.endpoint = endpoints.generator_profile_detail(this.profile.pk);
                if(!this.profile.applications.generator.data.pk){
                    this.method = 'POST';
                    this.endpoint = endpoints.generator_profile();
                }
                // Fetch data from endpoint
                if(this.profile.applications.generator.data.pk){
                    this.get_settings();
                }
            },
            get_settings() {
                axios.get(endpoints.generator_profile_detail(this.profile.pk))
                .then(
                    (response) => {
                        this.form.voice_tone = response.data.voice_tone;
                        this.form.writing_style = response.data.writing_style;
                        this.form.use_agency_name = response.data.use_agency_name;
                    },
                    (response) => {
                        if (response.response.status == 403) {
                            toast.error(this.$t('generator.profile.planNeeded'), this.$t('common.error'));
                            this.noplan = true;
                        } else {
                            toast.error(this.$t('generator.profile.errorPostLoad'), this.$t('common.error'));
                        }
                    }
                );
            },
            saveCallback(){
                if(!this.signedup){
                    window.location = routes.generator(this.profile.slug);
                }
            },
            cancel_subscription(){
                this.saving = true;
                axios.post(
                    profileEndpoints.profile_cancel_subscription(this.profile.pk),
                    {'application_slug': 'generator'},
                ).then(
                    () => {
                        toast.success(this.$t('common.unsubscribed'), this.$t('generator.common.postGenerator'));
                        window.location.href = '/';
                    }
                ).catch(
                    () => {
                        toast.error(this.$t('generator.profile.errorUnsubscribe'), this.$t('common.error'));
                    }
                ).finally(() => {
                    this.saving = false;
                });
            },
        },
        computed: {
            editDestinationsUrl() {
                return views.seller_details(this.profile.slug);
            }
        },
        created: function () {
            this.loadData();
        }
    }
</script>

<template>
    <li class="d-flex justify-content-between align-items-center mb-3">
        <span>
            <i :class="`fa ${connectedAppConfig.icon} em-icon text-${connectedAppConfig.id} align-middle`"></i>
            <span class="align-middle ml-2 text-capitalize" v-text="connectedApp ? profile.applications.emarketing.data.name : connectedAppConfig.name"></span>
        </span>
        <b-spinner
            v-if="isLoading"
            small
            variant="success"
            :label='$t("connection.state")'>
        </b-spinner>
        <a v-else-if="!connectedApp" :href="routes.connect(profile.slug, 'emarketing')">
            <span class="reset-link">
                <small>{{ $t("connection.itemLabelNotConnected") }}</small>
            </span>
        </a>
        <small v-else class="text-green">{{ $t("connection.itemLabelConnected") }}</small>
    </li>
</template>

<script>
    import { connectionConfigValues } from './apps-connection-config';
    import { routes } from '@/components/common/views-config.js';

    export default {
        name: 'AppsConnectionItem',
        props: {
            profile: {
                type: Object,
                required: true
            }
        },
        data: function () {
            return {
                connectedApp: '',
                connectedAppConfig: connectionConfigValues['emarketing'],
                isLoading: false,
                routes: routes
            };
        },
        methods: {
            validate() {
                this.isLoading = true;
                let _em = this.connectedAppConfig.id;
                if (this.$props.profile.applications[_em].is_enabled) {
                    this.connectedApp = true;
                }
                this.isLoading = false;
            }
        },
        mounted() {
            this.validate();
        }
    }
</script>

<style scoped>
.em-icon {
    font-size: 1.7em;
}
</style>
